/* @tailwind base; is already included in the Phia UI component library, so no need to include it here css */
@tailwind components;
@tailwind utilities;

/* Apply font smoothing globally */
html,
body {
  -webkit-font-smoothing: antialiased; /* For Webkit browsers (Chrome, Safari) */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
}

/*
    Hides scrollbars on certain browsers
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* IMAGE CROPPER STYLES */

.cropper-container {
  overflow: hidden;
  border-radius: 8px;
}

.cropper-hidden {
  display: none !important;
}

.cropper-invisible {
  opacity: 0;
}

/* Crop box */
.cropper-crop-box {
  border: 4px solid white; /* Thicker white border */
  border-radius: 16px; /* More pronounced rounded corners */
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4); /* Subtle shadow */
}

/* Overlay surrounding crop box */
.cropper-modal {
  background-color: gray !important;
}

/* Drag box */
.cropper-drag-box {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: black;
}

/* Drag handles styling */
.cropper-point {
  opacity: 0 !important;
  background-color: black !important; /* White drag handles */
  width: 12px; /* Adjust size */
  height: 12px; /* Adjust size */
  border-radius: 50%; /* Circular drag handles */
}

.cropper-view-box {
  border: 0px;
  outline: none !important;
  outline-width: 0px;
  border-radius: 10px;
  border-color: transparent;
  outline-color: transparent;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow inside the crop box */
}

.cropper-line {
  background-color: white !important;
  border: none;
  background: none !important;
}

.cropper-canvas {
  filter: blur(4px); /*Apply blur effect to background*/
}
